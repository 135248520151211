import React, { useState } from "react";
import styles from "./Header.module.css";
import jinpeng from "./../../images/jin02.jpg";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faMoneyBillTransfer,
  faHashtag,
} from "@fortawesome/free-solid-svg-icons";
import { style } from "framer-motion/client";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: "-100%" },
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <header>
      <div className={`app-container ${styles.header}`}>
        <div className={styles.lefticon}>
          <img src={jinpeng} />
          $JIN
        </div>

        <div className={styles.rightbox}>
          <div
            onClick={() => scrollToSection("cards")}
            className={styles.buynow}
          >
            Social Media
            <FontAwesomeIcon icon={faHashtag} />
          </div>

          <div className={styles.buynow} onClick={() => setIsOpen(!isOpen)}>
            Buy JIN
            <FontAwesomeIcon icon={faMoneyBillTransfer} />
          </div>

          {/* {isOpen ? ( */}
          <motion.div
            animate={isOpen ? "open" : "closed"}
            variants={variants}
            className={styles.downermenu}
          >
            <a target="_blank" href="https://jup.ag/swap/JIN-SOL">
              <div className={styles.eachmenit}>Buy on Jupiter</div>
            </a>
            <a target="_blank" href="https://lbank.com/trade/jin_usdt">
              <div className={styles.eachmenit}>Buy on LBank </div>
            </a>
          </motion.div>
        </div>
      </div>
    </header>
  );
};

export default Header;

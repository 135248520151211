import React from "react";
import styles from "./Buyers.module.css";
import lbank from "../../images/lbank.png";
import jupiter from "../../images/jupiter-logo.svg";
const Buyers = () => {
  return (
    <div className={styles.buyerser}>
      <div className={styles.eachb}>
        <a href="https://jup.ag/swap/JIN-SOL" target="_blank">
          <div className={`${styles.buyerbtn} ${styles.jupiter}`}>
            <img src={jupiter} /> Buy on Jupiter
          </div>
        </a>
      </div>

      <div className={styles.eachb}>
        <a href="https://lbank.com/trade/jin_usdt" target="_blank">
          <div className={`${styles.buyerbtn} ${styles.lbank}`}>
            Buy on <img src={lbank} />
          </div>
        </a>
      </div>
    </div>
  );
};

export default Buyers;

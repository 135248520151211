import React, { useEffect } from "react";

const CoinWidget = () => {
  useEffect(() => {
    // اینجا اسکریپت ویجت رو به DOM اضافه می‌کنیم
    const script = document.createElement("script");
    script.src = "https://files.coinmarketcap.com/static/widget/currency.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // اگر نیاز به تمیزکاری (cleanup) باشه، اینجا می‌تونی script رو حذف کنی
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div style={{ transform: "scale(1.1)" }}>
      <div
        className="coinmarketcap-currency-widget"
        data-currencyid="33051"
        data-base="USD"
        data-secondary=""
        data-ticker="false"
        data-rank="false"
        data-marketcap="false"
        data-volume="false"
        data-statsticker="false"
        data-stats="USD"
        dangerouslySetInnerHTML={{
          __html: `<div   className="coinmarketcap-currency-widget"
  data-currencyid="33051"
  data-base="USD"
  data-secondary=""
  data-ticker="false"
  data-rank="false"
  data-marketcap="false"
  data-volume="false"
  data-statsticker="false"
  data-stats="USD"></div> `,
        }}
      />
    </div>
  );
};

export default CoinWidget;

import React from "react";
import styles from "./CoinGeek.module.css";
import { Link } from "react-router-dom";
import coing from "./../../images/coingeeko.png";
const CoinGeek = () => {
  return (
    <div className={styles.containbtner}>
      <Link target="_blank" to="https://www.coingecko.com/en/coins/jinpeng">
        <div
          className={styles.coinbtn}
          // onClick={() => {
          //   shower == "open" ? setShower("closed") : setShower("open");
          // }}
        >
          <div className={styles.cointitle}>
            <img src={coing} className={styles.svgc} />
            LET'S GO!!!
          </div>

          {/* <div className={styles.coinon}></div> */}
        </div>
      </Link>
    </div>
  );
};

export default CoinGeek;
